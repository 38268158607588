import Tabbar from '../common/Tabbar.vue'
import Loading from '../../module/common/Loading.vue'
import Share from '../../module/common/Share.vue'
import { Icon, Tag, Cell, Image } from 'vant'
import '../../../assets/icon/font-icon.js'
export default {
    components: {
        Tabbar: Tabbar,
        Loading: Loading,
        Share: Share,
        [Icon.name]: Icon,
        [Tag.name]: Tag,
        [Cell.name]: Cell,
        [Image.name]: Image
    },
    data () {
        return {
            loadingShow: false,
            model: '',
            seller: {},
            settleCount: { prior: 0, wait: 0, complete: 0 },
            wallet: {}
        }
    },
    mounted () {
        this.model = window.sessionStorage.getItem(this.SESSION_SELLER_MODEL)
        this.retrieveSeller()
        this.retrieveSellerCount()
        this.retrieveInviterCount()
        // this.retrieveRefereeCount()
        this.$refs.share.default('seller')
    },
    methods: {
        password () {
            this.$router.push({ path: '/mde/account/pwdModify' })
        },
        order () {
            this.$router.push({ path: '/seller/order' })
        },
        task () {
            this.$router.push({ path: '/seller/task' })
        },
        settle () {
            this.$router.push({ path: '/seller/settle' })
        },
        register () {
            this.$router.push({ path: '/seller/statsRegister' })
        },
        visit () {
            this.$router.push({ path: '/seller/statsVisit' })
        },
        async retrieveWallet () {
            var pd = { merchantCode: this.seller.merchantCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/retrieveWallet', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.wallet = res.data
            }
        },
        walletRecord () {
            this.$router.push({ path: '/mde/wallet/busRecord', query: { merchantCode: this.seller.merchantCode } })
        },
        walletExtract () {
            this.$router.push({ path: '/mde/wallet/busExtract', query: { merchantCode: this.seller.merchantCode } })
        },
        async retrieveSeller () {
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { code: seller }
            const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/retrieveSeller', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.seller = res.data
                this.retrieveWallet()
            }
        },
        async retrieveSellerCount () {
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: seller }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETSellerCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleCount.prior += res.data.prior
                this.settleCount.wait += res.data.wait
                this.settleCount.complete += res.data.complete
            }
        },
        async retrieveInviterCount () {
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var pd = { sellerCode: seller }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETInviterCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleCount.prior += res.data.prior
                this.settleCount.wait += res.data.wait
                this.settleCount.complete += res.data.complete
            }
        },
        async retrieveRefereeCount () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { refereeCode: user }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETRefereeCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleCount.prior += res.data.prior
                this.settleCount.wait += res.data.wait
                this.settleCount.complete += res.data.complete
            }
        },
        signIn () {
            this.$dialog.alert({
                title: '提示信息',
                message: '签到成功'
            })
        },
        walletDetail () {
            this.$dialog.alert({
                title: '提示信息',
                message: '暂无交易信息'
            })
        },
        async payment () {
            var code = window.sessionStorage.getItem(this.SESSION_USER)
            var amount = 9.9
            this.loadingShow = true
            var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
            this.orderCode = code
            this.amount = amount
            var pd = {
                appid: 'wxa8145a74e5f2b39a',
                amount: this.amount * 100,
                attach: this.taskCode,
                description: '高考咨询服务支付',
                body: '高考咨询服务支付',
                detail: '',
                business: this.code,
                openid: serial
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createJsapiPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                var that = this
                this.payOrderCode = res.data.code
                var param = res.data.param
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', param,
                    function (rs) {
                        if (rs.err_msg === 'get_brand_wcpay_request:ok') {
                            that.retrievePayStatus()
                        } else {
                            that.loadingShow = false
                        }
                    })
            }
        },
        async retrievePayStatus () {
            var pd = { appid: 'wxa8145a74e5f2b39a', code: this.payOrderCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付成功'
                })
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付失败'
                })
            }
            this.loadingShow = false
        },
        person () {
            var url = window.location.href
            window.sessionStorage.setItem(window.const.global.SELLER_PERSON_INDEX_BACK, url)
            this.$router.push({ path: '/seller/person' })
        },
        consult () {
            this.$router.push({ path: '/seller/consult' })
        },
        buy () {
            this.$router.push({ path: '/seller/buy' })
        },
        async love () {
            const { data: res } = await this.$http.post(this.UMS_URL + '/supply/supplier/retrieveLoginSupplierUser')
            if (res.status === '200') {
                window.sessionStorage.setItem(this.SESSION_SUPPLIER, res.data.supplierCode)
                this.$router.push({ path: '/love/release' })
            } else {
                this.$dialog.confirm({
                    title: '提示信息',
                    message: '确认开通婚恋服务吗?'
                })
                    .then(() => {
                        this.openSupplierService()
                    })
            }
        },
        async openSupplierService () {
            var pd = { merchantCode: this.seller.merchantCode, module: 'LOV' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/supply/supplier/openService', this.$qs.stringify(pd))
            if (res.status === '200') {
                window.sessionStorage.setItem(this.SESSION_SUPPLIER, res.data.supplierCode)
                this.$router.push({ path: '/mde/love/release' })
            }
        }
    }
}
