<template>
  <div class="page">
    <van-row class="top">
      <van-col span="6"><van-icon name="user-circle-o" size="50px" /></van-col>
      <van-col span="8">
        <van-row>
          <van-col span="24" class="name"
            >{{ seller.name }} <van-icon name="edit"
          /></van-col>
        </van-row>
        <van-row>
          <van-col span="24" class="grade"
            ><van-tag :color="COLOR_M">{{ seller.gradeName }}</van-tag></van-col
          >
        </van-row>
      </van-col>
      <van-col span="10">
        <van-row>
          <van-col span="24" class="expire color-s1"
            >{{ seller.expireDate }}到期</van-col
          >
        </van-row>
        <van-row>
          <van-col span="24" class="buy"
            ><van-tag plain size="mini" :color="COLOR_M" @click="buy"
              ><van-icon name="cart-o" /> 立即续费</van-tag
            ></van-col
          >
        </van-row>
      </van-col>
    </van-row>
    <div class="wallet">
      <van-row>
        <van-col span="5" class="icon"
          ><van-icon name="balance-pay" size="40px"
        /></van-col>
        <van-col span="10" class="amount">金豆：{{ wallet.beanBalance }}个</van-col>
        <van-col span="9" class="more"
          ><van-button
            plain
            :color="COLOR_S1"
            icon="balance-list-o"
            size="mini"
            @click="walletRecord"
            >明细</van-button
          ><van-button
            plain
            :color="COLOR_S2"
            icon="cash-back-record"
            size="mini"
            @click="walletExtract"
            >提现</van-button
          ></van-col
        >
      </van-row>
    </div>
    <van-row class="settle">
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-m">{{ settleCount.prior }}元</div>
        <div class="text">待办理收益</div></van-col
      >
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-s1">{{ settleCount.wait }}元</div>
        <div class="text">待结算收益</div></van-col
      >
      <van-col span="8"
        ><div class="amount color-s2">{{ settleCount.complete }}元</div>
        <div class="text">已结算收益</div></van-col
      >
    </van-row>
    <!-- <div class="advice" @click="payment()">
      <van-image
        class="img"
        :src="require('../../../assets/images/seller/adv-01.jpg')"
      ></van-image>
    </div> -->
    <div class="navigate">
      <van-cell is-link to="index" @click="password()">
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-password"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 修改密码</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell is-link to="index" @click="settle()">
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-settle"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 订单结算</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="order()" is-link v-if="model === 'TSF'">
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-order"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 订单详情</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="person()" is-link v-if="model === 'TSF'">
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-person-m"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 我的客户</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="consult()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-consult"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 客户咨询</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="register()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-register"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 客户注册</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="visit()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-click"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 客户来访</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="task()" is-link v-if="model === 'TSF'">
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-verify"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 考核任务</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="love()" is-link v-if="model === 'TSF'">
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-love-1"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 智能婚恋</van-col>
          </van-row>
        </template></van-cell
      >
    </div>
    <Tabbar active="own" />
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
